import React from 'react'
import { Link } from 'gatsby';
import { Box, Heading, Container, Text } from 'theme-ui';

export default function BlogPage(props) {
  const blogPosts = props.data.allContentfulPost.edges;
  return (
    <Container sx={{ px: '15px' }}>
      <Heading sx={{ mt: '35px', mb: '40px', textAlign: 'center', fontFamily: 'crimson text' }}>All Posts</Heading>
        {blogPosts.map((post, i) => {
          return(
            <Link style={{ textDecoration: 'none', color: '#333333' }} to={`/blog/${post.node.slug}`}>
            <Box sx={{ m: ['0 auto' ], p: '20px', my: '20px', minHeight: '160px', width: ['100%', null, null, '700px'], bg: 'rgb(245, 245, 245)', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 3px 0px', '&:hover': { cursor: 'pointer', boxShadow: 'rgba(0, 0, 0, 0.6) 0px 1px 3px 0px' } }}>
            <Text sx={{ fontFamily: 'crimson text', fontWeight: 'bold', fontSize: '23px' }}>{post.node.title}</Text>
          <Text sx={{ mt: '10px', fontSize: '16px', fontFamily: 'crimson text' }}>{post.node.description}</Text>
          <Text sx={{ mt: '10px', fontFamily: 'crimson text', color: 'rgb(100, 100, 100)' }}>{post.node.date}</Text>
          </Box>
            </Link>
          )
        })}
    </Container>
  );
}

export const blogPageQuery = graphql`
  query BlogQuery {
    allContentfulPost {
      edges {
        node {
          body {
            body
          }
          contentful_id
          description
          date
          title
          slug
        }
      }
    }
  }
`